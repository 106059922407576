::-webkit-scrollbar-track
{
	border: none;
	background-color: rgba(0,0,0,0);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(0,0,0,0);
}

::-webkit-scrollbar-thumb
{
	background-color: #9E9E9E;	
}